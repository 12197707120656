.ag-theme-quartz-dark {
    --ag-background-color: rgb(34, 34, 34);
    --ag-header-background-color: rgb(34, 34, 34);
    --ag-font-size: 16px;
    --ag-row-hover-color: #A48400;

}

.center-cell {
    display: flex !important;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.ag-header-cell-label {
    justify-content: center;
}