.cup-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0 !important;
    transform: scale(1);
    transition: transform 100ms ease;

}

.cup-item:hover {
    transform: scale(1.2);
}

.cup-item img {
    width: 75px;
    height: 75px;
}

.cup-item-selected {
    animation: 1s rock-icon linear infinite;
}

@keyframes rock-icon {
    0% {
        transform: scale(1.2) rotate(-10deg);
    }
    50% {
        transform: scale(1.2) rotate(10deg);
    }
    100% {
        transform: scale(1.2) rotate(-10deg);
    }
}