.links-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    width: 200px;
    border: 1px solid;
    border-image: linear-gradient(to top right, #FFFF00, #A48400, #FFFF00, #A48400) 1;
    background: #222222;
    margin-top: 20px;
}

.link {
    color: white !important;
}