.info-modal {
    width: 66%;
    max-width: 1000px;
    margin: 20px auto auto;
}

.link-image {
    width: 20px;
    padding: 0;
}

.country-flag {
    font-size: 2em;
}