.donate-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    margin-top: 20px;
    border: 1px solid;
    border-image: linear-gradient(to top right, #FFFF00, #A48400, #FFFF00, #A48400) 1;
    background: rgba(34, 34, 34, 1) !important;
}