.home-container {
    width: 100%;
    /*background: #222222 !important;*/
    /*border: 2px solid;*/
    /*border-image: linear-gradient(to top right, #FFFF00, #A48400, #FFFF00, #A48400) 1;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*padding-top: 32px;*/
    padding-bottom: 32px;
}

.home-link {
    color: white;
    text-decoration: none;
    background: #222222;
    border: 2px solid;
    border-image: linear-gradient(to top right, #FFFF00, #A48400, #FFFF00, #A48400) 1;
    margin-top: 16px;
    margin-bottom: 16px;
}

.home-link:hover {
    background: #2c2c2c;
    --angle: 0deg;
    border-image: conic-gradient(from var(--angle), #FFFF00, #A48400, #FFFF00, #A48400) 1;
    animation: 1s rotate linear infinite;
}

.heading {
    font-weight: bold;
    font-size: 2em;
    text-align: center;
}


.link-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.boo {
    height: 1.5em;
    filter: grayscale(100%);
}

@keyframes rotate{
    to {
        --angle: 360deg;
    }
}

@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}