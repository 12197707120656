.category-selector {
    margin-bottom: 40px;
}

.category-selector button {
    color: #A48400;
    border-color: #A48400;
}

.category-selector button:hover {
    color: #FAC802;
    border-color: #FAC802;
    z-index: 10;
}

.selected {
    font-weight: 700 !important;
    color: #FAC802 !important;
    border-color: #FAC802 !important;
    border-width: 2px !important;
    z-index: 10;
}