.upload-form {
    padding: 16px;
    margin: auto;
    justify-content: center;
}

.confirm-button {
    margin-top: 2px;
    font-weight: bold;
}
