.upload-form {
    padding: 16px;
    margin: auto;
    justify-content: center;
}

.url-input {
    margin-left: 1px;
    margin-right: 1px;
}

.confirm-button {
    margin-top: 2px;
    font-weight: bold !important;
}

.loading-button{
    color: rgb(111, 111, 111) !important;
    border-color: rgb(111, 111, 111) !important;
}