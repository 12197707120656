.leaderboard-grid {
    --ag-grid-size: 5px;
    --ag-list-item-height: 20px;
    --ag-font-size: 14px;
    --ag-row-hover-color: #A48400;
    --ag-border-color: gold;
    margin-top: 10px;
    height: 100%;
    width: 300px;
}

.center-cell {
    display: flex !important;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.left-cell {
    display: flex !important;
    text-align: left;
    justify-content: left;
    align-items: center;
}

.first {
    color: gold;
    font-weight: bold
}

.second {
    color: silver;
    font-weight: bold
}

.third {
    color: #cd7f32;
    font-weight: bold
}

.center-grid {
    display: flex;
    justify-content: center;
    align-items: center;
}