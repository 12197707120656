.preview-container {
    width: 66%;
    max-width: 1000px;
    margin: 20px auto auto;
}

.alert-container {
    width: 100%;
    margin: auto;
}

.ghost-upload {
    padding-top: 1px;
    overflow-x: hidden;
    background: #222222 !important;
    border: 2px solid;
    border-image: linear-gradient(to top right, #FFFF00, #A48400, #FFFF00, #A48400) 1;
}

.cell-header {
    font-weight: bold;
}

.ghost-card {
    display: flex;
    width: 50%;
    margin: auto auto 20px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 2px solid;
    border-image: linear-gradient(to top right, #FFFF00, #A48400, #FFFF00, #A48400) 1;
}

.track-name {
    margin-bottom: 20px;
}

.images-container {
    display: flex;
    justify-content: center;
    margin: 20px;
    align-items: center;
}

.mii-image,
.character-image,
.vehicle-image,
.controller-image,
.country-flag {
    height: 50px;
    margin: 0 10px;
}

.details-container, .times-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.shrooms-image {
    height: 15px;
    padding: 0 0 0 10px;
    margin: 0;
    vertical-align: middle;
}

.field-name {
    font-weight: bold !important;
}