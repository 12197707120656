.header {
    background: #222222 !important;
    border-bottom: 2px solid;
    border-image: linear-gradient(to top right, #FFFF00, #A48400, #FFFF00, #A48400) 1;
}

.header-nav {
    display: flex;
    gap: 20px;
}

.header-nav a {
    text-decoration: none;
    font-size: 0.8em;
}

.title-button {
    color: white !important;
    text-transform: none !important;
    font-weight: bold !important;
}

#leaderboard-menu, #player-menu, #upload-menu {
    color: white;
}

#leaderboard-menu a, #player-menu a, #upload-menu a {
    text-decoration: none !important;
    color: white;
}

#leaderboard-menu ul, #player-menu ul, #upload-menu ul {
    background: #222222;
    border: 2px solid;
    --angle: 0deg;
    border-image: conic-gradient(from var(--angle), #FFFF00, #A48400, #FFFF00, #A48400) 1;
    animation: 1s rotate linear infinite;
}

@keyframes rotate {
    to {
        --angle: 360deg;
    }
}

@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}
